import { Tag, Icon, Swipe, SwipeItem } from 'vant'
import Login from '../../intercept/Login.vue'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            code: '',
            personCode: '',
            loadingShow: false,
            inform: {}
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.retrieveInform()
        var key = window.const.global.MEMBER_PERSON_MATCH_CHOSE
        var personCode = window.sessionStorage.getItem(key)
        if (personCode !== null && personCode !== undefined && personCode !== '') {
            this.personCode = personCode
            window.sessionStorage.removeItem(key)
            this.createConsult()
        }
    },
    methods: {
        async retrieveInform () {
            var pd = { code: this.code }
            var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                console.log(res.data)
                this.inform = res.data
                this.createViewClue()
            }
        },
        recruit () {
            this.$router.push({ path: '/mde/recruit/search', query: { classifyCode: '1' } })
        },
        subscribe () {
        },
        async signup () {
            var pd = {
                taskCode: this.code,
                type: 'SGP'
            }
            var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/validateOrderExist', this.$qs.stringify(pd))
            if (res.status === '200') {
                if (res.data.result !== 'true') {
                    this.$router.push({ path: '/mde/inform/signup', query: { code: this.code } })
                } else {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: '已报名完成，请耐心等待客服回访'
                    })
                }
            }
        },
        consult () {
            var vad = this.validateToken()
            if (vad) {
                var backKey = window.const.global.MEMBER_PERSON_MATCH_BACK
                window.sessionStorage.setItem(backKey, window.location.href)
                this.$router.push({ path: '/mde/member/personMatch', query: { taskCode: this.code, business: 'IFM', profile: 'RECRUIT_NORMAL' } })
            }
        },
        async createConsult () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, taskCode: this.code, personCode: this.personCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/validateConsult', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.payExpense(9.9, 'ODR')
            }
        },
        async payExpense (amount, type) {
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_MOC',
                amount: amount,
                attach: '',
                description: '咨询服务购买',
                body: '咨询服务购买',
                detail: '',
                business: '',
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.executeCreateConsult()
            }
        },
        async executeCreateConsult () {
            this.loadingShow = true
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var pd = {
                taskCode: this.code,
                personCode: this.personCode,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee,
                amount: '0',
                type: 'ODR'
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/createConsult', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.createOrderClue(res.data.code)
                this.$dialog.alert({
                    title: '咨询申请',
                    message: '咨询申请成功,请耐心等待'
                })
            } else {
                this.$dialog.alert({
                    title: '申请失败',
                    message: res.msg
                })
            }
        },
        validateToken () {
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token === null) {
                this.$router.push({ path: '/mde/bind' })
                return false
            } else {
                return true
            }
        },
        createViewClue () {
            var param = { taskCode: this.code, issuerCode: this.issuerCode, url: window.location.href }
            var content = '公招公示浏览'
            this.$refs.clue.createClue('INFORM_VIEW_INFORM', this.code, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { taskCode: this.code, orderCode: orderCode, url: window.location.href }
            var content = '公招公示订单'
            this.$refs.clue.createClue('INFORM_ORDER_INFORM', orderCode, JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.INFORM_DETAIL_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
