<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <Navbar title="公招信息" type="SMP" :callback="back" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/recruit/consult.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <div class="name">{{ inform.name }}</div>
    <van-row class="operate">
      <van-col :span="14">
        时间：{{ inform.startTime }}到{{ inform.endTime }}
      </van-col>
      <van-col :span="10"
        ><van-tag :color="COLOR_M" size="medium"
          >已收藏:{{ inform.collectTotal }}</van-tag
        >&nbsp;&nbsp;<van-tag :color="COLOR_S2" size="medium"
          >分享:{{ inform.shareTotal }}
        </van-tag>
      </van-col>
    </van-row>
    <div class="content" v-html="inform.content"></div>
    <div class="guide">
      <van-row class="item">
        <van-col :span="4" class="type bgcolor-m">报名</van-col>
        <van-col :span="14" class="text text-short"
          >线上专业简历制作投递一键搞定
        </van-col>
        <van-col :span="6" class="btn bgcolor-m" @click="signup()"
          ><van-icon name="certificate icon" /> 立即报名</van-col
        >
      </van-row>
      <van-row class="item">
        <van-col :span="4" class="type bgcolor-s1">咨询</van-col>
        <van-col :span="14" class="text text-short">
          一对一电话回访咨询(9.9元/次)</van-col
        >
        <van-col :span="6" class="btn bgcolor-s1" @click="createConsult()"
          ><van-icon name="records" /> 立即咨询</van-col
        >
      </van-row>
      <!-- <van-row class="item">
        <van-col :span="4" class="type bgcolor-m">订阅</van-col>
        <van-col :span="14" class="text text-short">
          最新全国公招信息实时提醒通知</van-col
        >
        <van-col :span="6" class="btn bgcolor-m" @click="subscribe()"
          ><van-icon name="bullhorn-o" /> 立即订阅</van-col
        >
      </van-row> -->
      <van-row class="item">
        <van-col :span="4" class="type bgcolor-s2">招聘</van-col>
        <van-col :span="14" class="text text-short">
          海量名企高薪岗位可立即入职</van-col
        >
        <van-col :span="6" class="btn bgcolor-s2" @click="recruit()"
          ><van-icon name="coupon-o" /> 立即查看</van-col
        >
      </van-row>
    </div>
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="IFM" module="IFM_DETAIL_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
